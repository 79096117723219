import styled from "styled-components";
import { PiArrowLeftLight, PiGridNineLight, PiRulerLight, PiTrashLight } from "react-icons/pi";
import { useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { useRouter } from "@tanstack/react-router";

const StyledOverlay = styled.div`
	top: 50%;
	transform: translate(0, -50%);
	position: absolute;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem;
	gap: 1rem;

	button {
		padding: 1rem;
		border: none;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.grey};
		background-color: ${({ theme }) => theme.colors.white};
		box-shadow: 0 0 1rem rgb(from ${({ theme }) => theme.colors.grey} r g b / 0.2);
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.icon {
		width: 1rem;
		height: 1rem;
	}
`;

const Overlay = () => {
	// const showWalls = useAppStore((store) => store.showWalls);
	const showGrid = useAppStore((store) => store.showGrid);
	const showMeasurements = useAppStore((store) => store.showMeasurements);
	const update = useAppStore((store) => store.update);
	const reset = useAppStore((store) => store.reset);
	const { navigate } = useRouter();

	return (
		<StyledOverlay>
			<button
				title={translations.return[DEFAULT_LOCALE]}
				onClick={() => window.confirm(translations.confirmReturn[DEFAULT_LOCALE]) && navigate({ to: "/" })}>
				<PiArrowLeftLight className="icon" />
			</button>
			<button
				title={translations.reset[DEFAULT_LOCALE]}
				onClick={() => window.confirm(translations.confirmReset[DEFAULT_LOCALE]) && reset()}>
				<PiTrashLight className="icon" />
			</button>
			{/* <button
				title={showWalls ? translations.hideWalls[DEFAULT_LOCALE] : translations.showWalls[DEFAULT_LOCALE]}
				onClick={() => update({ showWalls: !showWalls })}>
				<PiWallLight className="icon" />
			</button> */}
			<button
				title={showGrid ? translations.hideGrid[DEFAULT_LOCALE] : translations.showGrid[DEFAULT_LOCALE]}
				onClick={() => update({ showGrid: !showGrid })}>
				<PiGridNineLight className="icon" />
			</button>
			<button
				title={
					showMeasurements
						? translations.hideMeasurements[DEFAULT_LOCALE]
						: translations.showMeasurements[DEFAULT_LOCALE]
				}
				onClick={() => update({ showMeasurements: !showMeasurements })}>
				<PiRulerLight className="icon" />
			</button>
		</StyledOverlay>
	);
};

export default Overlay;
