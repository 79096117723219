import CONFIG, { RoofCode } from "@/config";
import { SegmentCode, Wall, WallCode, WallLocation } from "@/config/walls";
import translations, { DEFAULT_LOCALE, Locale } from "@/translations";
import { invalidate } from "@react-three/fiber";
import { Color, Material, Mesh, MeshStandardMaterial, Object3D } from "three";

export { createPDF } from "./pdf";

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.substring(1);

export const isNull = (input: unknown): input is null => input === null;
export const isUndefined = (input: unknown): input is undefined => input === undefined;
export const isNullOrUndefined = (input: unknown): input is null | undefined => isNull(input) || isUndefined(input);
export const isNotNullOrUndefined = <T,>(input: T | null | undefined): input is T => !isNullOrUndefined(input);

export const isObject3D = (input: unknown): input is Object3D =>
	typeof input === "object" && (input as Object3D).isObject3D;

export const isMesh = (input: unknown): input is Mesh => typeof input === "object" && (input as Mesh).isMesh;

export const isMaterial = (input: unknown): input is Material =>
	typeof input === "object" && (input as Material).isMaterial;

export const isMeshStandardMaterial = (input: unknown): input is MeshStandardMaterial =>
	typeof input === "object" && (input as MeshStandardMaterial).isMeshStandardMaterial;

export const sortInBuckets = <T extends object>(source: T[], key: keyof T): Record<string, T[]> =>
	source.reduce<Record<string, T[]>>((acc, item) => {
		const value = item[key];

		if (!value) {
			return acc;
		}

		const bucketKey = String(value);
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (!acc[bucketKey]) {
			acc[bucketKey] = [];
		}
		acc[bucketKey].push(item);

		return acc;
	}, {});

const createIsSegmentTest =
	<Matches extends SegmentCode[]>(...matches: Matches) =>
	(segmentCode: SegmentCode): segmentCode is Matches[number] =>
		matches.includes(segmentCode);

export const isSingleWindowSegment = createIsSegmentTest("16SW", "23SW");
export const isLeftDoorSegment = createIsSegmentTest("16DL", "23DL");
export const isRightDoorSegment = createIsSegmentTest("16DR", "23DR");
export const isVentSegment = createIsSegmentTest("16FA", "23FA", "16FAW", "23FAW");
export const isSingleTiltingSegment = createIsSegmentTest("16ST", "23ST");
export const isDoubleTitltingSegment = createIsSegmentTest("16DT", "23DT");
export const isWindowSegment = createIsSegmentTest("16SW", "23SW", "16ST", "23ST", "16DT", "23DT");
export const isDoorSegment = createIsSegmentTest("16DL", "23DL", "16DD", "23DD", "16DR", "23DR");

export const hasWallLocation = (input: unknown): input is Object3D & { userData: { location: WallLocation } } =>
	isObject3D(input) && input.userData.location !== undefined;

export const transformTemplate = (template: string, replacements: Record<string, string>) =>
	template.replace(/{\s*(\w+)\s*}/g, (match, key) => (key in replacements ? replacements[key] : match));

export const transformTranslation = (
	key: keyof typeof translations,
	replacements: Record<string, string>,
	locale: Locale = DEFAULT_LOCALE
) => transformTemplate(translations[key][locale], replacements);

type Options = {
	threshold?: number;
	lighterOffset?: [number, number, number];
	darkerOffset?: [number, number, number];
};

export const darkenOrBrighten = (
	color: Color,
	options: Options = { threshold: 0.5, lighterOffset: [0, 0, 0.1], darkerOffset: [0, 0, -0.1] }
) => {
	const { threshold = 0.5, lighterOffset = [0, 0, 0.1], darkerOffset = [0, 0, -0.1] } = options;
	const hsl = { h: Infinity, s: Infinity, l: Infinity };
	color.getHSL(hsl);
	color.offsetHSL(...(hsl.l < threshold ? lighterOffset : darkerOffset));
};

export const createMap = (inMin: number, inMax: number, outMin: number, outMax: number) => (input: number) => {
	const slope = (outMax - outMin) / (inMax - inMin);
	const output = outMin + slope * (input - inMin);

	return output;
};

/**
 * @description Maps range [inMin, inMax] to [outMin, outMax].
 * @param input The number to map
 * @param inMin
 * @param inMax
 * @param outMin
 * @param outMax
 * @returns The mapped input parameter.
 */
export const map = (input: number, ...args: Parameters<typeof createMap>): number => createMap(...args)(input);

export const getSegmentSize = (segment: SegmentCode): number =>
	segment.startsWith("16") ? CONFIG.wallSizes.inner.XS : segment.startsWith("23") ? CONFIG.wallSizes.inner.S : Infinity;

export const getRoofById = (id: RoofCode) => CONFIG.roofs.byId[id];
export const getWallById = (id: WallCode) => CONFIG.walls.byId[id];
export const getWallsByWidth = (() => {
	const EMPTY_LIST: Wall[] = [];
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return (width: number) => CONFIG.walls.byWidth[width] ?? EMPTY_LIST;
})();

export const startAnimation = (callback: FrameRequestCallback) => {
	invalidate();
	requestAnimationFrame(callback);
};

export const waitUntilNextFrame = () => {
	return new Promise((resolve) => requestAnimationFrame(resolve));
};
