import { createFileRoute, Outlet } from "@tanstack/react-router";
import styled from "styled-components";
import Scene from "@/components/three/Scene";
import Sidepanel from "@/components/ui/Sidepanel";
import { useEnvironment } from "@react-three/drei";
import { ENVIRONMENT_FILE } from "@/globals";
import Overlay from "@/components/ui/Overlay";
import Logo from "@/components/ui/Logo";

export const Route = createFileRoute("/_configurator")({
	loader: () => useEnvironment.clear({ files: ENVIRONMENT_FILE }), // Prevent issues with gainmaps.
	shouldReload: ({ cause }) => (cause === "enter" ? true : false), // Only re-run loader on initial enter.
	component: Configurator,
});

function Configurator() {
	return (
		<ConfiguratorContainer>
			<SceneContainer className="scene">
				<Scene />
				<Overlay />
				<div className="logo">
					<Logo variant="dark" />
				</div>
			</SceneContainer>
			<Sidepanel className="sidepanel">
				<Outlet />
			</Sidepanel>
		</ConfiguratorContainer>
	);
}

const SceneContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
`;

const ConfiguratorContainer = styled.div`
	width: 100%;
	height: 100svh;
	background-color: ${({ theme }) => theme.colors.background_light};

	display: flex;

	.sidepanel {
		width: ${({ theme }) => theme.sizes.sidepanel};
		flex-shrink: 0;
	}

	.logo {
		position: absolute;
		top: 1rem;
		left: 1rem;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
		font-size: 12px;
		flex-direction: column;

		.sidepanel {
			width: auto;
			height: 50svh;
		}
	}
`;
