import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, lazy } from "react";

const PillarComponents = {
	HV06: lazy(() => import("./HV06-00")),
};

export interface PillarProps extends GroupProps {
	type: keyof typeof PillarComponents;
}

const Pillar: FunctionComponent<PillarProps> = ({ type, ...otherProps }) => {
	const Pillar = PillarComponents[type];

	return <Pillar {...otherProps} />;
};

export default Pillar;
