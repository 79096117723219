export const theme = {
	fonts: {
		primary: "Lato",
	},
	sizes: {
		sidepanel: "500px",
	},
	breakpoints: {
		desktop: "1280px",
		tablet: "968px",
	},
	colors: {
		primary: "#ee7203",
		primary_light: "#fed4af",
		primary_text: "#ffffff",
		background_light: "#f4f4f2",
		background_dark: "#161616",
		black: "#000000",
		white: "#ffffff",
		light_grey: "#e6e6e6",
		grey: "rgba(0, 0, 0, 0.6)",
		dark_grey: "rgba(0, 0, 0, 0.8)",

		info: "lightblue",
		warning: "lightcoral",
	},
	radius: {
		small: "4px",
		medium: "8px",
		large: "12px",
	},
	gap: {
		extra_small: "0.25rem",
		small: "0.5rem",
		medium: "1rem",
		large: "2rem",
		extra_large: "4rem",
	},
	padding: {
		extra_small: "0.25rem",
		small: "0.5rem",
		medium: "1rem",
		large: "1.5rem",
		extra_large: "2rem",
	},
};
