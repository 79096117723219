import { useAppStore } from "@/stores/appStore";
import { useMemo } from "react";

const useConfiguration = () => {
	const roof = useAppStore((store) => store.roof);
	const walls = useAppStore((store) => store.walls);
	const color = useAppStore((store) => store.color);

	return useMemo(
		() => ({
			roof,
			walls,
			color,
		}),
		[roof, walls, color]
	);
};

export default useConfiguration;
