/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/models/divider.glb --types --exportdefault --root public --precision 6 
*/

import * as THREE from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import CONFIG from "@/config";

type GLTFResult = GLTF & {
	nodes: {
		Divider: THREE.Mesh;
		Divider_1: THREE.Mesh;
	};
	materials: {
		["Wall - Exterior"]: THREE.MeshStandardMaterial;
		["Wall - Interior"]: THREE.MeshStandardMaterial;
	};
	animations: GLTFAction[];
};

export default function Model(props: React.JSX.IntrinsicElements["group"]) {
	const { nodes, materials } = useGLTF("/models/divider.glb") as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh
				geometry={nodes.Divider.geometry}
				material={materials["Wall - Exterior"]}
				material-color={CONFIG.colors.anthracite}
				material-roughness={1}
				material-metalness={0}
			/>
			<mesh
				geometry={nodes.Divider_1.geometry}
				material={materials["Wall - Interior"]}
				material-color={CONFIG.colors.anthracite}
				material-roughness={1}
				material-metalness={0}
			/>
		</group>
	);
}

useGLTF.preload("/models/divider.glb");
