import { forwardRef, FunctionComponent, HTMLProps } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

const StyledLabel = styled.label`
	padding: ${({ theme }) => theme.padding.small};
	font-size: 0.875rem;
	border: 1px solid ${({ theme }) => theme.colors.light_grey};

	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;

	input {
		padding: 0;
		border: none;
		font-size: 1rem;
		margin-top: ${({ theme }) => theme.padding.extra_small};

		&:focus {
			outline: none;
		}
	}

	.error {
		color: ${({ theme }) => theme.colors.warning};
		font-size: 0.75rem;
	}
`;

type TextInputProps = HTMLProps<HTMLInputElement> & { label: string; error?: FieldError; isRequired?: boolean };
const TextInput: FunctionComponent<TextInputProps> = forwardRef(function TextInput(
	{ label, error, isRequired = false, ...inputProps },
	ref
) {
	return (
		<StyledLabel>
			{label + (isRequired ? "*" : "")}
			<input {...inputProps} type="text" ref={ref} />
			{error && <span className="error">{error.message}</span>}
		</StyledLabel>
	);
});

export default TextInput;
