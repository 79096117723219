import { WallLocation } from "@/config/walls";
import { selectors, useAppStore } from "@/stores/appStore";
import { startTransition, useEffect, useState } from "react";

/**
 * @description This hook exists solely as a wrapper around the store.
 * Transitions are not supported for Zustand, so the store value
 * needs to be wrapped in state so that the UI is only updated once
 * the transition is complete.
 * @param location The location of the wall you'd like to retrieve.
 * @returns The wall configured at the desired location.
 */
export default function useWall(location: WallLocation) {
	const wall = useAppStore(selectors.getWallByLocation(location));
	const [wallState, setWallState] = useState(wall);

	useEffect(() => {
		startTransition(() => {
			setWallState(wall);
		});
	}, [wall]);

	return wallState;
}
