import { Billboard, Line, LineProps, Text } from "@react-three/drei";
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box3, Group, Vector3 } from "three";

type Axis = "X" | "Y" | "Z";
export type RenderCallback = (distance: number, axis: Axis) => ReactNode;

interface MeasuredProps extends Omit<LineProps, "points"> {
	offset?: number;
	showX?: boolean;
	showY?: boolean;
	showZ?: boolean;
	render?: RenderCallback;
}

export default function Measured({
	children,
	visible,
	offset = 0.1,
	showX = true,
	showY = true,
	showZ = true,
	...props
}: MeasuredProps) {
	const groupRef = useRef<Group>(null);
	const [boundingBox, setBoundingBox] = useState<Box3>(() => new Box3());

	useLayoutEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		setBoundingBox(new Box3().setFromObject(groupRef.current!));
	}, [children, offset]);

	const bounds = useMemo(() => {
		return {
			x: {
				from: new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
				to: new Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
				normal: new Vector3(0, 0, 1).multiplyScalar(offset),
			},
			y: {
				from: new Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
				to: boundingBox.max,
				normal: new Vector3(1, 0, 0).normalize().multiplyScalar(offset),
			},
			z: {
				from: boundingBox.min,
				to: new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
				normal: new Vector3(-1, 0, 0).multiplyScalar(offset),
			},
		};
	}, [offset, boundingBox]);

	return (
		<>
			<group ref={groupRef}>{children}</group>
			<group visible={visible}>
				{showX && <MeasureLine axis="X" {...bounds.x} {...props} />}
				{showY && <MeasureLine axis="Y" {...bounds.y} {...props} />}
				{showZ && <MeasureLine axis="Z" {...bounds.z} {...props} />}
			</group>
		</>
	);
}

interface MeasureLineProps extends Omit<MeasuredProps, "showX" | "showY" | "showZ"> {
	from: Vector3;
	to: Vector3;
	normal: Vector3;
	axis: Axis;
}

function MeasureLine({ from, to, normal, render = defaultRender, axis, ...props }: MeasureLineProps) {
	const { points, mid, distance } = useMemo(() => {
		const mid = new Vector3().addVectors(from, to).multiplyScalar(0.5).add(normal);
		const distance = from.distanceTo(to);

		return { points: [from, to], mid, distance };
	}, [from, normal, to]);

	if (isNaN(distance)) return null;

	return (
		<group position={normal}>
			<Line points={points} {...props} />
			<Billboard position={mid}>{render(distance, axis)}</Billboard>
		</group>
	);
}

function defaultRender(distance: number) {
	return (
		<Text fontSize={0.1} color="black">
			{`${distance.toFixed(2)}m`}
		</Text>
	);
}
