import { StateCreator } from "zustand";
import CONFIG, { ColorVariant, RoofCode } from "@/config";
import { Configuration, WallCode, WallLocation } from "@/config/walls";
import CameraControls from "camera-controls";
import { RootState } from "@react-three/fiber";

const isDevMode = import.meta.env.DEV;

export type State = {
	roof: RoofCode;
	walls: Configuration;
	selectedWall?: WallLocation;
	color: ColorVariant;
	showGrid: boolean;
	showWalls: boolean;
	showMeasurements: boolean;
	isDevMode: boolean;
	controls?: CameraControls;
	three?: RootState;
};

export type Actions = {
	setRoof: (roofCode: RoofCode) => void;
	toggleSelectedWall: (location: WallLocation) => void;
	setWallByLocation: (location: WallLocation, wall: WallCode) => void;
	setWallBySelected: (wall: WallCode) => void;
	update: (options: Partial<State>) => void;
	reset: () => void;
};

const DEFAULT_ROOF = Object.values(CONFIG.roofs.byId)[1]; // Just a placeholder to init the store.
const DEFAULT_WALLS = {
	left: undefined,
	right: undefined,
	front: undefined,
	back: undefined,
};

const DEFAULT_STATE = {
	walls: DEFAULT_WALLS,
	color: "anthracite" as ColorVariant,
};

const store: StateCreator<State & Actions> = (set) => ({
	...DEFAULT_STATE,
	isDevMode,
	showGrid: true,
	showWalls: true,
	showMeasurements: false,
	roof: DEFAULT_ROOF.id,
	selectedWall: undefined,
	setRoof: (roofCode) => {
		set((state) => ({
			...state,
			roof: roofCode,
			walls: DEFAULT_WALLS,
		}));
	},
	toggleSelectedWall: (location) =>
		set((state) => ({
			...state,
			selectedWall: state.selectedWall !== location ? location : undefined,
		})),
	setWallByLocation: (location, wall) => set((state) => ({ ...state, walls: { ...state.walls, [location]: wall } })),
	setWallBySelected: (wall) =>
		set((state) => (state.selectedWall ? { ...state, walls: { ...state.walls, [state.selectedWall]: wall } } : state)),
	update: (options) => set((state) => ({ ...state, ...options })),
	reset: () => set((state) => ({ ...state, ...DEFAULT_STATE })),
});

export default store;
