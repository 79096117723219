import CONFIG from "@/config";
import { getRoofById, getWallById, getWallsByWidth, isNotNullOrUndefined } from "@/utils";
import { Color } from "three";
import { Wall, WallLocation } from "@/config/walls";
import { Roof } from "@/config/roofs";
import { State } from "./store";

export const selectors = {
	getRoofBySelected: (state: State): Roof => {
		return getRoofById(state.roof);
	},

	getWallBySelected: (state: State): Wall | undefined => {
		const wallId = state.selectedWall && state.walls[state.selectedWall];
		return isNotNullOrUndefined(wallId) ? getWallById(wallId) : undefined;
	},

	getAvailableWallsByLocation: (location?: WallLocation) => {
		return (state: State): Wall[] => {
			const selectedRoof = selectors.getRoofBySelected(state);

			return location === "front" || location === "back"
				? getWallsByWidth(selectedRoof.width)
				: location === "left" || location === "right"
					? getWallsByWidth(selectedRoof.depth)
					: [];
		};
	},

	getAvailableWallsBySelected: (state: State) => {
		return selectors.getAvailableWallsByLocation(state.selectedWall)(state);
	},

	getWallByLocation: (location: WallLocation) => {
		return (state: State): Wall | undefined => {
			const wallId = state.walls[location];
			return wallId && getWallById(wallId);
		};
	},

	getWallSize: (location?: WallLocation) => {
		return (state: State): number => {
			const selectedRoof = selectors.getRoofBySelected(state);

			return location === "front" || location === "back"
				? selectedRoof.width
				: location === "left" || location === "right"
					? selectedRoof.depth
					: Infinity;
		};
	},

	getPillarColor: (state: State): Color => CONFIG.colors[state.color === "white" ? "white" : "anthracite"],
	getWallColor: (state: State): Color => CONFIG.colors[state.color === "duo" ? "white" : state.color],
};
