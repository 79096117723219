import { WallLocation } from "@/config/walls";
import { useSelected } from "@/hooks/useSelected";
import { selectors, useAppStore } from "@/stores/appStore";
import { theme } from "@/theme";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { Line, Text } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { Color } from "three";

const selectionColor = new Color(theme.colors.primary);
const fontSize = 0.15;
const points: [number, number, number][] = [
	[-0.5, 0, 0],
	[0.5, 0, 0],
];

interface LineIndicatorProps extends GroupProps {
	location: WallLocation;
}

export const LineIndicator = ({ location, ...groupProps }: LineIndicatorProps) => {
	const isSelected = useSelected(location);
	const width = useAppStore(selectors.getWallSize(location));

	return (
		<group {...groupProps} visible={isSelected}>
			<Line layers={1} points={points} scale={width} lineWidth={3} color={selectionColor} visible={isSelected} />
			<Text
				layers={1}
				color="black"
				anchorX="right"
				fontSize={fontSize}
				position={[width / 2, 0, fontSize]}
				rotation-x={-Math.PI / 2}>
				{translations[location][DEFAULT_LOCALE]}
			</Text>
		</group>
	);
};
