import { PDFButton, PDFDocument, PDFTextField } from "pdf-lib";
import * as fontkit from "@pdf-lib/fontkit";
import { Fontkit } from "pdf-lib/cjs/types/fontkit";
import { isUndefined } from "@/utils";

type Map = Record<string, unknown>;

const installFont = async (document: PDFDocument, path: string) => {
	const response = await fetch(path);
	const bytes = await response.arrayBuffer();
	const font = document.embedFont(bytes);

	return font;
};

const loadPDF = async (path: string, fonts?: string[]) => {
	// Load PDF
	const response = await fetch(path, { cache: "no-cache" });
	const bytes = await response.arrayBuffer();
	const document = await PDFDocument.load(bytes);

	// Install fonts
	if (!isUndefined(fonts)) {
		// TODO: Casting fontkit shouldn't be necessary; weird TS bug.
		document.registerFontkit(fontkit as Fontkit);
		await Promise.all(fonts.map((font) => installFont(document, font)));
	}

	return document;
};

const hydrateFields = async (document: PDFDocument, map: Map) => {
	const form = document.getForm();
	const fields = form.getFields();

	// Hydrate the fields.
	for (const field of fields) {
		const name = field.getName();
		const value = map[name];

		if (field instanceof PDFTextField) {
			field.setText(String(value));
		} else if (field instanceof PDFButton && value instanceof Blob) {
			const bytes = await document.embedPng(await value.arrayBuffer());
			field.setImage(bytes);
		}
	}

	// Set the form to read only.
	form.flatten();

	return document;
};

type CreatePDF = {
	(path: string, map: Map, asBlob?: false): Promise<string>;
	(path: string, map: Map, asBlob: true): Promise<Blob>;
};

export const createPDF = (async (path, map, asBlob = false) => {
	const templatePDF = await loadPDF(path);
	const completedPDF = await hydrateFields(templatePDF, map);

	if (asBlob) {
		const bytes = await completedPDF.save();
		return new Blob([bytes.buffer], { type: "application/pdf" });
	} else {
		return completedPDF.saveAsBase64();
	}
}) as CreatePDF;
