import { useAppStore } from "@/stores/appStore";
import { PerformanceMonitor } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import { ReactNode } from "react";

type PerformanceProps = {
	children: ReactNode;
};

export default function Performance({ children }: PerformanceProps) {
	const isDevMode = useAppStore((store) => store.isDevMode);
	const setDpr = useThree((state) => state.setDpr);
	return (
		<>
			<PerformanceMonitor
				flipflops={5}
				bounds={() => [30, 50]}
				onIncline={() => setDpr(2)}
				onDecline={() => setDpr(1)}
				onFallback={() => setDpr(1)}>
				{children}
			</PerformanceMonitor>
			{isDevMode && <Perf position="top-left" />}
		</>
	);
}
