import Image from "./Image";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Card, { CardProps } from "./Card";
import { FunctionComponent } from "react";

interface CompoundCard extends FunctionComponent<CardProps> {
	Image: typeof Image;
	Title: typeof Title;
	Subtitle: typeof Subtitle;
}

(Card as CompoundCard).Image = Image;
(Card as CompoundCard).Title = Title;
(Card as CompoundCard).Subtitle = Subtitle;

export default Card as CompoundCard;
