import { useAppStore } from "@/stores/appStore";
import { useThree } from "@react-three/fiber";
import { memo } from "react";

const SyncStore = () => {
	const update = useAppStore((store) => store.update);
	const three = useThree();

	update({ three });

	return null;
};

export default memo(SyncStore);
