import CONFIG from "@/config";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, useCallback } from "react";
import EmptyWall from "./23FA-00";
import { getSegmentSize, isUndefined, isVentSegment } from "@/utils";
import useWall from "@/hooks/useWall";
import { WallLocation } from "@/config/walls";
import SelectionIndicator from "../SelectionIndicator";
import WithCursor from "../../Utils/WithCursor";
import Segment from "./Segments";
import { selectors, useAppStore } from "@/stores/appStore";
import { CSGProvider } from "@/hooks/useCSG";
import Divider from "./Segments/Divider";

interface WallProps extends GroupProps {
	location: WallLocation;
}

const Y_OFFSET = 0.001; // To alleviate Z-fighting along the Y axis.
const LABEL_OFFSET = 0.25; // Label distance from wall.

const Wall: FunctionComponent<WallProps> = ({ location, ...groupProps }) => {
	const wall = useWall(location);
	const wallSize = useAppStore(selectors.getWallSize(location));
	const toggleSelectedWall = useAppStore((store) => store.toggleSelectedWall);

	const emptyWallSize = CONFIG.wallSizes.inner.S;
	const wallWidth = wall ? CONFIG.wallSizes.inner[wall.size] : wallSize;
	const scalingFactor = wallWidth / emptyWallSize;
	const inverseScalingFactor = 1 / scalingFactor;

	const isUndecided = isUndefined(wall);
	const showVents = !isUndecided && wall.segments.some(isVentSegment);
	const segments = !isUndecided ? wall.segments : [];

	const onClickHandler = useCallback<Exclude<WallProps["onClick"], undefined>>(
		(event) => {
			event.stopPropagation();
			toggleSelectedWall(location);
		},
		[location, toggleSelectedWall]
	);

	return (
		<group {...groupProps}>
			<WithCursor onClick={onClickHandler}>
				<CSGProvider location={location}>
					<EmptyWall
						location={location}
						scale-x={scalingFactor}
						showVents={showVents}
						inverseScalingFactor={inverseScalingFactor}
						opacity={isUndecided ? 0.1 : 1}>
						{segments.map((type, index) => {
							const key = `segment-${String(index)}`;
							const segmentWidth = getSegmentSize(type);
							const position =
								(index === 0 ? (-wallWidth + segmentWidth) / 2 : (wallWidth - segmentWidth) / 2) * inverseScalingFactor;

							return <Segment key={key} type={type} position-x={position} scale-x={inverseScalingFactor} />;
						})}
						<Divider visible={wall?.needsDivider ?? false} scale-x={inverseScalingFactor} />
					</EmptyWall>
				</CSGProvider>
			</WithCursor>
			<SelectionIndicator location={location} position-y={Y_OFFSET} position-z={LABEL_OFFSET} />
		</group>
	);
};

export default Wall;
