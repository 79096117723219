import { wallSizes } from "./walls";

export type Roof = {
	id: string;
	name: string;
	depth: number;
	width: number;
	iconURL?: string;
};

const roofs: { byId: Record<string, Roof> } = {
	byId: {
		"00": {
			id: "00",
			name: "1600-1600",
			depth: wallSizes.outer.XS,
			width: wallSizes.outer.XS,
			iconURL: "icons/roofs/1600-1600.jpg",
		},
		"01": {
			id: "01",
			name: "2300-2300",
			depth: wallSizes.outer.S,
			width: wallSizes.outer.S,
			iconURL: "icons/roofs/2300-2300.jpg",
		},
		"02": {
			id: "02",
			name: "2300-1616",
			depth: wallSizes.outer.S,
			width: wallSizes.outer.M,
			iconURL: "icons/roofs/2300-1616.jpg",
		},
		"03": {
			id: "03",
			name: "2300-1623",
			depth: wallSizes.outer.S,
			width: wallSizes.outer.L,
			iconURL: "icons/roofs/2300-1623.jpg",
		},
		"04": {
			id: "04",
			name: "2300-2323",
			depth: wallSizes.outer.S,
			width: wallSizes.outer.XL,
			iconURL: "icons/roofs/2300-2323.jpg",
		},
		"05": {
			id: "05",
			name: "1616-1616",
			depth: wallSizes.outer.M,
			width: wallSizes.outer.M,
			iconURL: "icons/roofs/1616-1616.jpg",
		},
		"06": {
			id: "06",
			name: "1616-1623",
			depth: wallSizes.outer.M,
			width: wallSizes.outer.L,
			iconURL: "icons/roofs/1616-1623.jpg",
		},
		"07": {
			id: "07",
			name: "1616-2323",
			depth: wallSizes.outer.M,
			width: wallSizes.outer.XL,
			iconURL: "icons/roofs/1616-2323.jpg",
		},
		"08": {
			id: "08",
			name: "1623-1623",
			depth: wallSizes.outer.L,
			width: wallSizes.outer.L,
			iconURL: "icons/roofs/1623-1623.jpg",
		},
		"09": {
			id: "09",
			name: "1623-2323",
			depth: wallSizes.outer.L,
			width: wallSizes.outer.XL,
			iconURL: "icons/roofs/1623-2323.jpg",
		},
	},
};

export default roofs;
