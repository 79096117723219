import { ComponentProps, ReactNode } from "react";
import styled from "styled-components";
import Steps from "./Steps";

type SidepanelProps = {
	children: ReactNode;
} & ComponentProps<typeof SidepanelContainer>;

export default function Sidepanel({ children, ...props }: SidepanelProps) {
	return (
		<SidepanelContainer {...props}>
			<Steps />
			<SidepanelContent>{children}</SidepanelContent>
		</SidepanelContainer>
	);
}

const SidepanelContainer = styled.div`
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
`;

const SidepanelContent = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.padding.large};
	overflow-y: auto;
`;
