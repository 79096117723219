import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { createJSONStorage, persist } from "zustand/middleware";
import store, { Actions, State } from "./store";

const isDevMode = import.meta.env.DEV;

export { selectors } from "./selectors";

export const useAppStore = create(
	persist<State & Actions>(store, {
		name: "app-store",
		version: 1,
		storage: createJSONStorage(() => sessionStorage),
		partialize: (state) =>
			({
				roof: state.roof,
				walls: state.walls,
				color: state.color,
			}) as State & Actions,
	})
);

if (isDevMode) {
	mountStoreDevtool("AppStore", useAppStore);
}
