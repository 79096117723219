import { Color } from "three";
import roofs from "./roofs";
import walls, { Wall, wallSizes } from "./walls";
import { sortInBuckets } from "@/utils";
import { degToRad } from "three/src/math/MathUtils.js";

const colors: Record<WallColor, Color> = {
	grey: new Color("rgb(140, 150, 157)"), // RAL 7001
	white: new Color("rgb(236, 236, 231)"), // RAL 9001
	anthracite: new Color("rgb(79, 82, 80)"), // RAL 7043
};

const cameraDistance = 7;
const cameraAngle = 30;

const CONFIG = {
	colors,
	cameraDistance,
	cameraAngle,
	cameraHeight: Math.tan(degToRad(cameraAngle)) * cameraDistance,
	wallHeight: 2.08,
	wallThickness: 0.13,
	windowHeight: 1.4,
	doorStepHeight: 0.075,
	tiltingWindowHeight: 0.06,
	tiltingWindowDistance: 0.4,
	wallSizes,
	roofs,
	walls: {
		...walls,
		bySize: sortInBuckets(Object.values(walls.byId), "size"),
		byWidth: sortInBuckets(
			Object.values(walls.byId).map<Wall & { width: number }>((wall) => ({
				...wall,
				width: wallSizes.outer[wall.size],
			})),
			"width"
		),
	},
};

export type WallColor = "grey" | "anthracite" | "white";
export type PillarColor = "anthracite" | "white";
export type ColorVariant = "anthracite" | "grey" | "duo" | "white";
export type RoofCode = keyof typeof roofs.byId;
export default CONFIG;
