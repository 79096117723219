import { ComponentProps } from "react";
import styled from "styled-components";

const TitleContainer = styled.h3`
	padding: ${({ theme }) => theme.padding.small};
	color: ${({ theme }) => theme.colors.black};
	letter-spacing: 1px;
	text-decoration: none !important;
	font-weight: 400;
	font-size: 1.125rem;
`;

type TitleProps = ComponentProps<typeof TitleContainer>;
export default function Title({ children, ...props }: TitleProps) {
	return <TitleContainer {...props}>{children}</TitleContainer>;
}
