import { ComponentProps } from "react";
import styled from "styled-components";

const CardContainer = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	gap: ${({ theme }) => theme.gap.medium};
	padding: ${({ theme }) => theme.padding.extra_large};
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: white;

	border: 1px solid transparent;
	transition: border-color 0.15s ease;

	&:hover {
		border-color: ${({ theme }) => theme.colors.light_grey};
	}

	&.active {
		border-color: ${({ theme }) => theme.colors.primary};
	}

	&.active::after {
		content: "";

		position: absolute;
		top: 0.5rem;
		right: 0.5rem;

		background-color: ${({ theme }) => theme.colors.primary};
		border-radius: 50%;
		width: 0.875rem;
		height: 0.875rem;
	}
`;

export type CardProps = Omit<ComponentProps<typeof CardContainer>, "$active"> & { isActive?: boolean };
export default function Card({ children, isActive = false, className = "", ...props }: CardProps) {
	return (
		<CardContainer className={isActive ? className + "active" : className} {...props}>
			{children}
		</CardContainer>
	);
}
