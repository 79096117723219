import Button from "@/components/primitives/Button";
import { ComponentProps } from "react";
import { PiX } from "react-icons/pi";
import styled from "styled-components";

const StyledButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.dark_grey};
	transition: color 0.2s;

	&:hover {
		color: ${({ theme }) => theme.colors.black};
	}
`;
const ExitButton = (props: ComponentProps<typeof Button>) => (
	<StyledButton {...props}>
		<PiX />
	</StyledButton>
);

export default ExitButton;
