import { SegmentCode } from "@/config/walls";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, lazy } from "react";

const SingleWindow = lazy(() => import("./SW"));
const SingleTilting = lazy(() => import("./ST"));
const DoubleTilting = lazy(() => import("./DT"));
const LeftDoor = lazy(() => import("./DL"));
const RightDoor = lazy(() => import("./DR"));
const DoubleDoor = lazy(() => import("./DD"));

const segments: Partial<Record<SegmentCode, FunctionComponent>> = {
	"16SW": SingleWindow,
	"23SW": SingleWindow,
	"16ST": SingleTilting,
	"23ST": SingleTilting,
	"23DT": DoubleTilting,
	"16DL": LeftDoor,
	"23DL": LeftDoor,
	"16DR": RightDoor,
	"23DR": RightDoor,
	"16DD": DoubleDoor,
	"23DD": DoubleDoor,
};

type SegmentProps = {
	type: SegmentCode;
} & Omit<GroupProps, "children">;
function Segment({ type, ...props }: SegmentProps) {
	const Component = segments[type];

	return Component ? <Component {...props} /> : null;
}

export default Segment;
