import { ComponentProps } from "react";
import styled, { css } from "styled-components";

const TabContainer = styled.span<{ $isActive?: boolean }>`
	padding: 1rem 1.5rem;
	color: ${({ theme }) => theme.colors.grey};
	text-transform: uppercase;

	transition:
		color ease 0.3s,
		background-color ease 0.3s;

	&:hover {
		cursor: pointer;
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			color: ${({ theme }) => theme.colors.white};
			background-color: ${({ theme }) => theme.colors.primary};
		`}
`;

const TabsContainer = styled.span`
	display: inline-flex;
	padding: ${({ theme }) => theme.padding.small};
	background-color: ${({ theme }) => theme.colors.background_light};
`;

type RadioProps = ComponentProps<typeof TabsContainer>;
export default function Radio({ children, ...props }: RadioProps) {
	return <TabsContainer {...props}>{children}</TabsContainer>;
}

Radio.Item = TabContainer;
