import { ComponentProps } from "react";
import styled from "styled-components";

const ImageContainer = styled.img<{ $fit?: "cover" | "contain" }>`
	width: 100%;
	object-fit: ${({ $fit = "cover" }) => $fit};
	aspect-ratio: 1;
`;

type ImageProps = ComponentProps<typeof ImageContainer>;
export default function Image(props: ImageProps) {
	return <ImageContainer {...props} />;
}
