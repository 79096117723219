import CONFIG from "@/config";
import useColors from "@/hooks/useColors";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent } from "react";

interface WallProps extends GroupProps {
	type: string;
}
const Roof: FunctionComponent<WallProps> = ({ type, ...groupProps }) => {
	const { scene, materials } = useGLTF(`/models/D${type}.glb`);

	useColors(materials, {
		Surface: CONFIG.colors.anthracite,
		"Exterior Slats": CONFIG.colors.anthracite,
		"Interior Slats": CONFIG.colors.anthracite,
		"Roof - Surface": CONFIG.colors.anthracite,
		"Roof - Exterior Slats": CONFIG.colors.anthracite,
		"Roof - Interior Slats": CONFIG.colors.anthracite,
	});

	return <primitive object={scene} {...groupProps} />;
};

export default Roof;
