import { ComponentProps } from "react";
import styled from "styled-components";

const Image = styled.img`
	width: 11.25rem;
`;

const sources = {
	light: "/Telluria-logo.svg",
	dark: "/Telluria-logo-black.svg",
};

interface LogoProps extends Omit<ComponentProps<typeof Image>, "src"> {
	variant?: keyof typeof sources;
}

const Logo = ({ variant = "dark", ...props }: LogoProps) => (
	<Image alt="TELLURIA Steel of life" loading="eager" src={sources[variant]} {...props} />
);

export default Logo;
