import { Canvas } from "@react-three/fiber";
import Performance from "./Performance";
import Lights from "./Lights";
import Controls from "./Controls";
import Effects from "./Effects";
import Product from "./Product";
import Grid from "./Grid";
import { Suspense } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Box } from "@react-three/drei";
import { theme } from "@/theme";
import CONFIG from "@/config";
import SyncStore from "./Utils/SyncStore";
import { Layers } from "three";

const layers = new Layers();
layers.enable(1);

export default function Scene() {
	return (
		<Canvas
			dpr={1.5}
			shadows
			frameloop="demand"
			camera={{ fov: 45, position: [0, CONFIG.cameraHeight, CONFIG.cameraDistance], layers, near: 0.1, far: 100 }}>
			<color args={[theme.colors.background_light]} attach="background" />
			<Performance>
				<Controls />
				<Lights />
				<Effects />
				<ErrorBoundary fallback={<Box material-color="red" />}>
					<Suspense fallback={null}>
						<Product />
					</Suspense>
				</ErrorBoundary>
				<SyncStore />
				<Grid />
			</Performance>
		</Canvas>
	);
}
