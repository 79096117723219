import { useEffect, useMemo, useRef } from "react";

type Cloneable<T> = { clone: (deep?: boolean) => T };
type Disposable = { dispose: () => void };

const isDisposable = (input: unknown): input is Disposable =>
	typeof input === "object" && input !== null && "dispose" in input;

/**
 * Creates a per component clone of the object.
 * @param obj The object to clone. Note that its reference should be stable else it will create endless clones.
 * @param deep Whether the cloning should be deep or shallow.
 * @returns A cloned object.
 */
const useClone = <T extends Cloneable<T>>(obj: T, deep?: boolean): T => {
	const clonesRef = useRef<Disposable[]>([]);

	// Dispose all clones after unmounting.
	useEffect(() => {
		const clones = clonesRef.current;
		return () => clones.forEach((clone) => clone.dispose());
	}, []);

	return useMemo(() => {
		const clone = obj.clone(deep);

		if (isDisposable(clone)) {
			clonesRef.current.push(clone);
		}

		return clone;
	}, [obj, deep]);
};

export default useClone;
