import CONFIG from "@/config";
import Pillar from "./Pillar";
import { selectors, useAppStore } from "@/stores/appStore";
import Wall from "./Wall";
import Measured, { RenderCallback } from "../Utils/Measured";
import { Text } from "@react-three/drei";
import { useCallback } from "react";
import Roof from "./Roof";
import { ErrorBoundary } from "@sentry/react";

const Shed = () => {
	const showMeasurements = useAppStore((store) => store.showMeasurements);
	const showWalls = useAppStore((store) => store.showWalls);
	const { name, width, depth } = useAppStore(selectors.getRoofBySelected);

	const renderLabel = useCallback<RenderCallback>(
		(_, axis) => (
			<Text fontSize={0.1} color="black">
				{`${(axis === "X" ? width : axis === "Z" ? depth : CONFIG.wallHeight).toFixed(2)} m`}
			</Text>
		),
		[width, depth]
	);

	return (
		<group name="shed">
			<group name="pillars">
				<Measured visible={showMeasurements} color="black" render={renderLabel} offset={0.5}>
					<Pillar
						type="HV06"
						position={[-(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-0 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-1 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-2 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[-(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-3 * Math.PI) / 2}
					/>
				</Measured>
			</group>

			<group name="walls" visible={showWalls}>
				<Wall location="front" position={[0, 0, (depth - CONFIG.wallThickness) / 2]} />
				<Wall location="back" position={[0, 0, -(depth - CONFIG.wallThickness) / 2]} rotation-y={Math.PI} />
				<Wall location="left" position={[-(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={-Math.PI / 2} />
				<Wall location="right" position={[(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={Math.PI / 2} />
			</group>

			<ErrorBoundary>
				<Roof type={name} position-y={CONFIG.wallHeight} />
			</ErrorBoundary>
		</group>
	);
};

export default Shed;
